import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useForm} from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
  },
}));

const QuoteForm = () => {
  const classes = useStyles();
  const {register, handleSubmit} = useForm()

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
         Get A Quote
        </Typography>
        <form className={classes.form} name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <TextField
            margin="normal"
            inputRef={register}
            required
            fullWidthd
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
          />
        <TextField
            margin="normal"
            inputRef={register}
            required
            fullWidth
            id="company"
            label="Your Company"
            name="company"
            autoComplete="company"
            autoFocus
          />
          <TextField
            margin="normal"
            inputRef={register}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
        <TextField 
          margin="normal"
          inputRef={register}
          required
          multiline
          fullWidth
          name="phone"
          label="Phone Number"
          id="phone"
          autoFocus
        />
        <TextField 
          margin="normal"
          inputRef={register}
          required
          multiline
          fullWidth
          name="language"
          label="Language Of Interest"
          id="language"
          autoFocus
        />
        <TextField
          margin="normal"
          inputRef={register}
          id="datetime-local"
          label="Date and Time"
          type="datetime-local"
          required
          fullWidth
          // defaultValue="2021-01-24T10:30"
          InputLabelProps={{
            shrink: true,
          }}
      />
         
          <TextField 
          margin="normal"
          inputRef={register}
          multiline
          fullWidth
          autoFocus
          rowsMax={4}
          name="details"
          label="Details"
          type="textarea"
          id="details"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            SUBMIT
          </Button>
        </form>
      </div>
      </Container>
  );
}

export default QuoteForm