import styled from 'styled-components'

export const Button = styled.button`
    border-radius: 4px;
    background: ${({primary}) => (primary ? "#FF4040" : "#0467FB")};
    white-space: nowrap;
    padding: ${({ big }) => (big ? "16px 64px" : "10px 20px")};
    color: #fff;
    font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
    letter-spacing: .1rem;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    text-align: center;
    margin-right: auto;

    &:hover {
        transition: all 0.3s ease-out;
        background: ${({ primary }) => (primary ? "#0467FB" : "#FF404")};
    }

    @media screen and (max-width: 960px) {
        width: 80%;
    }
`